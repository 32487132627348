<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12>
        <div class="headline green--text text--darken-4 font-weight-medium">
          Send SMS
        </div>
      </v-flex>
      <v-flex sm12>
        <v-card style="width: 500px">
          <v-card-text>
            <v-select
                v-model='selectedSmsType'
                :items='smsTypes'
                item-text="text"
                item-value="id"
                label="Select sms template">
            </v-select>
            <v-text-field
              v-model.trim="dlgModel.Mobile"
              name="mobile"
              label="Recipient Mobile:"
              v-validate="{ required: true }"
              :error="!!errors.has('mobile')"
              :error-messages="errors.first('mobile')"
            ></v-text-field>
            <v-menu
                v-model="showDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dlgModel.Date"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-show='selectedSmsType > 0'
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dlgModel.Date"
                  @input="showDate = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
                v-model="showTime"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dlgModel.Time"
                    label="Time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-show='selectedSmsType > 0'
                ></v-text-field>
              </template>
              <v-time-picker
                  v-model="dlgModel.Time"
                  @input="showTime = false"
              ></v-time-picker>
            </v-menu>
            <v-text-field
                v-model.trim="dlgModel.BookingId"
                name="bookingId"
                label="Appointment Booking Id:"
                v-if='selectedSmsType === 1'
                v-validate="{ required: true }"
                :error="!!errors.has('bookingId')"
                :error-messages="errors.first('bookingId')"
            ></v-text-field>
            <v-text-field
                v-model.trim="dlgModel.AntiMedicineDays"
                name="antiMedicineDays"
                label="No. of days to avoid medicine before SPT test:"
                v-if='selectedSmsType === 3'
                v-validate="{ required: true }"
                :error="!!errors.has('antiMedicineDays')"
                :error-messages="errors.first('antiMedicineDays')"
            ></v-text-field>
            <div v-show="submit.error">
              <v-list-item two-line>
                <v-list-item-content>
                  <span class="red--text">{{ submit.message }}</span>
                </v-list-item-content>
              </v-list-item>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text :disabled="submit.working" @click="clear">
                Clear
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="submit.working"
                @click="saveDlg"
                >Send
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from "../../api/web";

export default {
  data() {
    return {
      dlgModel: {
        Mobile: "",
        Date: "",
        Time: "",
        BookingId: "",
        AntiMedicineDays: 3
      },
      showDate: false,
      showTime: false,
      // smsType: 0: Thank You, 1: Appointment Confirmation, 2: Appointment Reminder, 3: SPT Test Reminder
      selectedSmsType: 0,
      smsTypes: [
        {id: 0, text: 'Thank You'},
        {id: 1, text: 'Appointment Confirmation'},
        {id: 2, text: 'Appointment Reminder'},
        {id: 3, text: 'SPT Test Reminder'}
      ],
      submit: {
        error: false,
        working: false,
        message: ""
      }
    };
  },
  methods: {
    clear() {
      this.dlgModel = {
        Mobile: "",
        Date: "",
        Time: "",
        BookingId: "",
        AntiMedicineDays: 3
      };
      this.submit = {
        error: false,
        working: false,
        message: ""
      };
      this.$validator.errors.clear();
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      this.api_create(this.dlgModel);
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post(`/sms/Send${this.getUrl()}`, data);
      this.submit.working = false;
      if (res.Success) {
        this.$notify({ type: "info", text: "Successfully sent message" });
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    getUrl(){
      switch(this.selectedSmsType) {
        case 0: return 'ThankYou';
        case 1: return 'AppointmentConfirmation';
        case 2: return 'AppointmentReminder';
        case 3: return 'SPTTestReminder';
      }
      return '';
    }
  }
};
</script>

<style scoped></style>
